export const password =
  +[] +
  [+!![]] +
  (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
  ([][[]] + [])[!![] + !![]] +
  (![] + [])[+[]] +
  (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
  (![] + [])[+[]] +
  (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
  (+(
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +!![]
  ) + [])[!![] + !![]] +
  ([][[]] + [])[!![] + !![]] +
  +!![] +
  ([][[]] + [])[!![] + !![]] +
  +[] +
  (+(
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +!![]
  ) + [])[!![] + !![]] +
  (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
  +[] +
  (!![] + !![] + !![] + !![] + !![]) +
  ([] +
    [][
      (!![] + [])[!![] + !![] + !![]] +
        ([][[]] + [])[+!![]] +
        (!![] + [])[+[]] +
        (!![] + [])[+!![]] +
        ([![]] + [][[]])[+!![] + [+[]]] +
        (!![] + [])[!![] + !![] + !![]] +
        (![] + [])[!![] + !![] + !![]]
    ]())[!![] + !![]] +
  (+(
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +!![]
  ) + [])[!![] + !![]] +
  (![] + [])[+!![]] +
  (!![] + !![] + !![] + !![]) +
  ([] +
    [][
      (!![] + [])[!![] + !![] + !![]] +
        ([][[]] + [])[+!![]] +
        (!![] + [])[+[]] +
        (!![] + [])[+!![]] +
        ([![]] + [][[]])[+!![] + [+[]]] +
        (!![] + [])[!![] + !![] + !![]] +
        (![] + [])[!![] + !![] + !![]]
    ]())[!![] + !![]] +
  +!![] +
  (+(
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +[] +
    +!![]
  ) + [])[!![] + !![]] +
  ([] +
    [][
      (!![] + [])[!![] + !![] + !![]] +
        ([][[]] + [])[+!![]] +
        (!![] + [])[+[]] +
        (!![] + [])[+!![]] +
        ([![]] + [][[]])[+!![] + [+[]]] +
        (!![] + [])[!![] + !![] + !![]] +
        (![] + [])[!![] + !![] + !![]]
    ]())[!![] + !![]] +
  (!![] + !![] + !![] + !![] + !![]) +
  (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
  (!![] + !![] + !![]) +
  (![] + [])[+[]] +
  +[] +
  ([][[]] + [])[!![] + !![]] +
  (!![] + !![] + !![] + !![] + !![] + !![]) +
  (![] + [])[+!![]] +
  ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
  +!![] +
  (!![] + !![] + !![] + !![] + !![] + !![])

export const data = [][(![] + [])[+!![]] + (!![] + [])[+[]]][
  ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    ([][[]] + [])[+!![]] +
    (![] + [])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    (!![] + [])[+!![]] +
    ([][[]] + [])[+[]] +
    ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    (!![] + [])[+!![]]
](
  (!![] + [])[+!![]] +
    (!![] + [])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    ([][[]] + [])[+[]] +
    (!![] + [])[+!![]] +
    ([][[]] + [])[+!![]] +
    (+[![]] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+!![]]] +
    (![] + [])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    (![] + [])[+!![]] +
    (!![] + [])[+[]] +
    ([][[]] + [])[+[]] +
    (![] + [])[!![] + !![] + !![]] +
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    ([][[]] + [])[+!![]] +
    (![] + [])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    (!![] + [])[+!![]] +
    ([][[]] + [])[+[]] +
    ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
    (!![] + [])[+[]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    (!![] + [])[+!![]] +
    (+(+!![] + [+!![]] + (!![] + [])[!![] + !![] + !![]] + (!![] + !![]) + +[]) + [])[+!![]] +
    (![] + [])[+[]] +
    (!![] + [])[+!![]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    ([] +
      (+[])[
        ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
          (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
          ([][[]] + [])[+!![]] +
          (![] + [])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          (!![] + [])[+!![]] +
          ([][[]] + [])[+[]] +
          ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
          (!![] + [])[+!![]]
      ])[+!![] + [+!![]]] +
    [][(![] + [])[+!![]] + (!![] + [])[+[]]][
      ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
        (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
        ([][[]] + [])[+!![]] +
        (![] + [])[!![] + !![] + !![]] +
        (!![] + [])[+[]] +
        (!![] + [])[+!![]] +
        ([][[]] + [])[+[]] +
        ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
        (!![] + [])[+[]] +
        (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
        (!![] + [])[+!![]]
    ](
      (!![] + [])[+!![]] +
        (!![] + [])[!![] + !![] + !![]] +
        (!![] + [])[+[]] +
        ([][[]] + [])[+[]] +
        (!![] + [])[+!![]] +
        ([][[]] + [])[+!![]] +
        (+[![]] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+!![]]] +
        (![] + [])[+!![]] +
        (!![] + [])[+[]] +
        (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
        ([] +
          [][
            (!![] + [])[!![] + !![] + !![]] +
              ([][[]] + [])[+!![]] +
              (!![] + [])[+[]] +
              (!![] + [])[+!![]] +
              ([![]] + [][[]])[+!![] + [+[]]] +
              (!![] + [])[!![] + !![] + !![]] +
              (![] + [])[!![] + !![] + !![]]
          ]())[!![] + !![]]
    )()(+[] + [+[]] + +[![]] + ![])[+!![]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    ([][[]] + [])[!![] + !![]] +
    (!![] + [])[!![] + !![] + !![]] +
    ([] +
      [][(![] + [])[+!![]] + (!![] + [])[+[]]][
        ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
          (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
          ([][[]] + [])[+!![]] +
          (![] + [])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          (!![] + [])[+!![]] +
          ([][[]] + [])[+[]] +
          ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
          (!![] + [])[+!![]]
      ](
        (!![] + [])[+!![]] +
          (!![] + [])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          ([][[]] + [])[+[]] +
          (!![] + [])[+!![]] +
          ([][[]] + [])[+!![]] +
          (+[![]] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+!![]]] +
          (![] + [])[!![] + !![] + !![]] +
          (!![] + [])[+[]] +
          (![] + [])[+!![]] +
          (!![] + [])[+[]] +
          ([][[]] + [])[+[]] +
          (![] + [])[!![] + !![] + !![]] +
          ([] +
            [][
              (!![] + [])[!![] + !![] + !![]] +
                ([][[]] + [])[+!![]] +
                (!![] + [])[+[]] +
                (!![] + [])[+!![]] +
                ([![]] + [][[]])[+!![] + [+[]]] +
                (!![] + [])[!![] + !![] + !![]] +
                (![] + [])[!![] + !![] + !![]]
            ]())[!![] + !![]] +
          (![] + [])[+!![]] +
          (!![] + [])[+!![]]
      )())[+!![] + [+!![]]] +
    (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
    ([![]] + [][[]])[+!![] + [+[]]] +
    ([][[]] + [])[+!![]] +
    (!![] + [])[+[]] +
    ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+!![]]] +
    (!![] +
      !![] +
      !![] +
      !![] +
      !![] +
      [!![] + !![]] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      +[] +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      +[] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      (!![] + !![]) +
      (!![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      +!![] +
      +!![] +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +[] +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![]) +
      ![] +
      +!![] +
      (!![] + !![]) +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      +[] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      +!![] +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      +[] +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      +!![] +
      +!![] +
      (!![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      (!![] + !![]) +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![]) +
      ![] +
      +!![] +
      (!![] + !![]) +
      +[] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![]) +
      (!![] + !![] + !![] + !![] + !![] + !![] + !![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![]) +
      (!![] + !![]) +
      ![] +
      +!![] +
      +[] +
      (!![] + !![] + !![]) +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      +!![] +
      ![] +
      (!![] + !![] + !![] + !![] + !![] + !![]) +
      +!![])[
      (![] + [])[!![] + !![] + !![]] +
        (+[![]] +
          [][(![] + [])[+!![]] + (!![] + [])[+[]]][
            ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
              (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
              ([][[]] + [])[+!![]] +
              (![] + [])[!![] + !![] + !![]] +
              (!![] + [])[+[]] +
              (!![] + [])[+!![]] +
              ([][[]] + [])[+[]] +
              ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[!![] + !![] + !![]] +
              (!![] + [])[+[]] +
              (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
              (!![] + [])[+!![]]
          ](
            (!![] + [])[+!![]] +
              (!![] + [])[!![] + !![] + !![]] +
              (!![] + [])[+[]] +
              ([][[]] + [])[+[]] +
              (!![] + [])[+!![]] +
              ([][[]] + [])[+!![]] +
              (+[![]] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+!![]]] +
              ([] +
                [][
                  (!![] + [])[!![] + !![] + !![]] +
                    ([][[]] + [])[+!![]] +
                    (!![] + [])[+[]] +
                    (!![] + [])[+!![]] +
                    ([![]] + [][[]])[+!![] + [+[]]] +
                    (!![] + [])[!![] + !![] + !![]] +
                    (![] + [])[!![] + !![] + !![]]
                ]())[!![] + !![]] +
              (!![] + [])[+[]] +
              (!![] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [+[]]] +
              (![] + [])[+!![]]
          )()([][[]]))[+!![] + [+[]]] +
        (![] + [])[!![] + !![]] +
        ([![]] + [][[]])[+!![] + [+[]]] +
        (!![] + [])[+[]]
    ](![]) +
    ([] + [][(![] + [])[+!![]] + (!![] + [])[+[]]])[+!![] + [!![] + !![]]]
)()
